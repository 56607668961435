<template>
  <div>
    <article>
      <h3 class="center">{{ appName }} App个人信息保护政策</h3>
      <p style="text-align: right">公布时间：2023年08月【03】日</p>
      <p style="text-align: right">生效时间：2023年08月【03】日</p>
      <p style="text-align: right">版本：第【一】版</p>
      <section>
        <h5>概述</h5>
        <p>
          成都超开心信息科技有限公司及关联公司（以下简称“超开心公司”“{{ appName }}”
          或“我们”）深知个人信息（含个人敏感信息）对您的重要性，并会全力保护您的个人信息安全。我们致力于维持您对我们的信任，并将恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、公开透明原则、确保安全原则、主体参与原则等。同时，超开心公司承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </p>
        <p>
          本个人信息保护政策（以下简称“本政策”）适用于超开心公司提供的{{
            appName
          }}产品和/或服务。如成都超开心信息科技有限公司及关联公司的产品和/或服务中使用了超开心公司提供的产品和/或服务但未设立独立的个人信息保护政策的，则本政策同样适用于该产品和/或服务。如我们就其向您提供的产品和/或服务单独设立有个人信息保护政策的，则相应的产品和/或服务适用于相应个人信息保护政策。
        </p>
        <p>
          在使用{{ appName }}产品和/或服务前，请您务必仔细阅读并透彻理解本政策，并做出您认为适当的选择。<span
            class="bold"
            >本政策中与您权益（可能）存在重大关系的条款，我们已使用加粗字体予以区别，请您重点查阅。如果您不同意本政策任何内容，您应立即停止使用我们的产品和/或服务，我们将无法为您提供产品和/或服务。您使用我们的产品和/或服务，即意味着您已阅读并同意我们按照本政策收集、使用、存储、共享、公开披露、保护您的相关信息。本政策进行更新后，我们会及时在“{{
              appName
            }}”平台发布最新版本，以便您及时查阅。</span
          >
        </p>
        <p class="bold">
          请您注意，我们已经尽量按照我国最新有关法律、法规、部门规章、规范性文件（为了便于表述，以下简称“法律法规”），参照最新的国家标准制定并执行本政策。但由于前述规定更迭频繁，不同部门、人员对同一规定可能存在诸多理解等限制。若本政策规定内容与我国届时有效的法律法规规定存在不一致或矛盾之处，应以我国届时有效的法律法规之规定为准，我们亦将按照届时有效的法律法规之有关规定处理您的个人信息并保障您的个人信息权益。
        </p>
        <p>本政策将帮助您了解以下内容：</p>
        <p>一、 本政策的适用范围和个人信息处理依据</p>
        <p>二、 我们如何收集和使用您的个人信息</p>
        <p>三、 我们如何使用Cookie和同类技术</p>
        <p>四、 我们如何存储及保护您的个人信息</p>
        <p>五、 我们如何委托处理、共享、转让、公开披露您的个人信息</p>
        <p>六、 我们如何保护您的个人信息</p>
        <p>七、 您的权利</p>
        <p>八、 您的个人信息如何在全球范围转移</p>
        <p>九、 本政策如何更新</p>
        <p>十、 如何联系我们</p>
        <p>十一、 争议解决</p>
        <p>相关术语及定义：</p>
        <p>1. 用户或您：指“{{ appName }}”平台的注册用户。</p>
        <p>
          2. “{{
            appName
          }}”平台：指成都超开心信息科技有限公司及其关联方开发、运营的互联网音乐平台，包括网页、APP客户端、公众号、小程序等。
        </p>
        <p>3. 超开心公司：指{{ appName }}产品和/或服务的运营方成都超开心信息科技有限公司及其关联公司。</p>
        <p>
          4.
          关联公司：指相互之间存在关联关系，关联关系是指超开心公司控股股东、实际控制人、董事、监事、高级管理人员与其直接或者间接控制的企业之间的关系，以及可能导致{{
            appName
          }}利益转移的其他关系。
        </p>
        <p>
          5.
          {{ appName }}产品和/或服务：指成都超开心信息科技有限公司及其关联公司通过“{{
            appName
          }}”平台向您提供的产品或服务，内容包括但不限于浏览、下载及管理小组件等基础服务，拍照、分享、发布信息内容等交互服务，以及会员订阅等增值服务。
        </p>
        <p>
          6. 个人信息：是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
        </p>
        <p>
          7.
          敏感个人信息：是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。
        </p>
        <p>8. 个人信息的处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</p>
        <p>
          9.
          收集：指获得对个人信息的控制权的行为，包括由个人信息主体主动提供、通过与个人信息主体交互或记录个人信息主体行为等自动采集，以及通过共享、转让、搜集公开信息间接获取等方式。
        </p>
        <p>10. 删除：在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</p>
        <p>11. 公开披露：向社会或不特定人群发布信息的行为。</p>
        <p>12. 共享：个人信息控制者向其他控制者提供个人信息，且双方分别对个人信息拥有独立控制权的过程。</p>
        <p>
          13.
          匿名化：通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。（注：个人信息经匿名化处理后所得的信息不属于个人信息）
        </p>
        <p>
          14. 去标识化：通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或者关联个人信息主体的过程。
        </p>
      </section>

      <section>
        <h5>一、本政策的适用范围和个人信息处理依据</h5>
        <p>1. 适用范围</p>
        <p>
          本政策适用于超开心公司提供的{{ appName }}产品和/或服务。您访问或登录“{{
            appName
          }}”平台相关客户端、公众号、小程序使用我们提供的产品和/或服务，均适用本政策。
        </p>
        <p>
          需要提醒您注意，本政策仅适用于我们所收集的信息，不适用于通过我们的产品和/或服务而接入的第三方产品和/或服务（包括任何第三方网站）收集的信息以及通过在我们产品和/或服务中进行广告服务的其他公司或机构所收集的信息。
        </p>
        <p class="bold">2. 个人信息处理依据</p>
        <p class="bold">
          除如下情形外，我们对您个人信息的处理均应取得您明确同意（包含依据有关法律、行政法规规定应当取得您的单独同意或者书面同意）：
        </p>
        <ul>
          <li>
            （1）
            为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
          </li>
          <li>（2） 为履行法定职责或者法定义务所必需；</li>
          <li>（3） 为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</li>
          <li>（4） 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</li>
          <li>（5） 依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</li>
          <li>（6） 法律、行政法规规定的其他情形。</li>
        </ul>
      </section>

      <section>
        <h5>二、我们如何收集和使用您的个人信息</h5>
        <p>（一）当您使用我们的服务时，我们需要收集和使用您如下个人信息</p>
        <p>
          <span class="bold">1. 产品服务提供及安全保障。</span
          >为了保障您使用我们产品与服务的安全性，识别账号异常状态、了解产品适配性、提供浏览基本服务，使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的需求，更好地预防欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入网络与信息安全风险，保护您、其他用户或公众人身财产安全免受侵害，我们需要采集您所使用设备的信息和日志信息。<span
            class="bold"
            >包括：设备品牌及型号、设备屏幕规格、操作系统版本、屏幕分辨率、浏览器类型、电信运营商、使用语言、硬件序列号、IP地址、MEID、Android
            ID、蓝牙地址、WLAN接入点（BSSID、SSID）、OAID、IMSI、IMEI、ICCID、IDFA、IDFV、GAID、Open
            UDID、用户使用终端设备访问本平台时的设备传感器数据、访问日期和时间信息、服务日志。</span
          >
        </p>
        <p>
          出于风控安全保障、向用户提供组件内容更新及提升客户体验之目的，我们需要在后台运行或静默状态下收集IMSI、设备MAC地址。
        </p>
        <p>
          为了预防恶意程序、提升运营安全及效率，我们会收集已安装的应用列表、安装的应用信息或正在运行的进程信息、应用程序的总体运行情况、总体安装使用情况、性能数据、应用来源，以判断账号及交易安全、识别违法违规情况，防范安全事件。
        </p>
        <p>（二）设备权限调用说明</p>
        <p>
          <span class="bold"> 1. 传感器权限：</span
          >当您打开内置 WebView 时，WebView 会调用设备的陀螺仪、加速度、重力、方向、地磁场
          传感器以识别判断您的设备状态。请您理解，单独的设备传感器数据不涉及任何个人位置信息且无法与其他信息结合用于识别特定自然人身份。
        </p>
        <p class="bold">（三）在法律法规允许范围内，我们可能会在以下情形中使用您的个人信息而无需征得您的授权同意：</p>
        <p class="bold">1. 与个人信息控制者履行法律法规规定的义务相关的；</p>
        <p class="bold">2. 与国家安全、国防安全直接相关的；</p>
        <p class="bold">3. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p class="bold">4. 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p class="bold">5. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p class="bold">6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p class="bold">7. 根据您的要求签订和履行合同所必需的；</p>
        <p class="bold">8. 用于维护所提供的产品和/或服务的安全稳定运行所必需的，例如发现、处置产品和/或服务的故障；</p>
        <p class="bold">9. 为合法的新闻报道所必须的；</p>
        <p class="bold">
          10.
          出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
        </p>
        <p class="bold">11. 法律法规规定的其他情形。</p>
      </section>

      <section>
        <h5>三、我们如何使用Cookie和同类技术</h5>
        <p>
          Cookie
          和设备信息标识等同类技术是互联网中普遍使用的技术。我们可能通过Cookies或同类技术收集您的设备型号、操作系统、唯一设备标识符、登录IP地址信息、以及缓存您的浏览信息、点击信息。我们向您发送Cookies是为了保障产品与服务的安全、高效运转，我们可能会设置认证与保障安全性的Cookie
          或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。此外，还可以简化您重复登录的步骤实现登录时无需重复输入用户名及密码信息，并帮助判断您的登录状态以及账户或数据安全。我们承诺，不会将
          Cookies用于本政策所述目的之外的任何用途。
        </p>
      </section>

      <section>
        <h5>四、我们如何存储及保护您的个人信息</h5>
        <p>
          我们会采取加密等技术措施存储您个人信息，确保信息安全。我们仅在本政策所述目的所必须期间和法律法规及监管规定的时间限内存储您的个人信息。我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。除法律或相关法规另有约定外，超开心公司在为提供服务之目的所必需的期间内保留您的个人信息，但您要求我们立即删除或注销账户、或法律法规另有规定的除外。
        </p>
        <p>
          如果我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
        </p>
      </section>

      <section>
        <h5>五、我们如何委托处理、共享、转让、公开披露您的个人信息</h5>
        <p>
          我们充分知晓因违法共享、公开披露个人信息对个人信息主体造成损害时所应承担的法律责任，我们对您的个人信息承担保密义务，不会出售或出租您的任何信息，对于您的个人信息的一切委托处理、共享、公开披露，我们将严格按照以下条款进行：
        </p>
        <p>（一）委托处理</p>
        <p>
          为了提高效率、降低成本，我们可能会委托第三方专业服务提供商代表我们来处理信息，例如我们会聘请专业服务提供商为我们提供基础设施技术服务、客服服务、广告服务，内容服务，增值服务。对我们委托处理个人信息的公司、组织和个人，我们会通过书面协议、现场审计等方式要求其遵守严格的保密义务并采取有效的保密措施，要求其只能在约定的委托范围内处理您的个人信息，而不得出于其自身利益使用您的个人信息。
        </p>
        <p>（二）共享</p>
        <p>我们不会与其他公司、组织和个人共享您个人信息，但以下情况除外：</p>
        <p>1. 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
        <p>
          2.
          与授权合作伙伴共享：我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的共享您的个人信息，授权合作伙伴只能接触到其提供的产品或服务所需信息，且我们会要求其不得将此信息用于其他任何目的，并按照法律法规及不低于本政策所要求的标准保护您的个人信息。
        </p>
        <p>3. 目前，我们的授权合作伙伴包括以下类型：</p>
        <p>
          （1）
          广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到您或识别您的身份）与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息（如设备信息），但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。
        </p>
        <p>
          （2）
          业务供应商、服务提供商。我们需要将您的设备信息、用户ID发送给支持我们业务的供应商、服务提供商和其他合作伙伴，用于提供SaaS营销等。
        </p>
        <p>
          （3）
          为了使您能够使用上述服务和功能，我们的应用中会嵌入授权合作方的SDK或其他类似的应用程序。我们会对此类接入的SDK或其他类似应用程序进行严格的安全监测，通过协议约束形式以保障数据安全。{{
            appName
          }}
          APP中接入的第三方SDK或其他类似的应用程序如下：<a :href="SDKUrl">请点击查看</a>。
        </p>
        <p>（三）公开披露</p>
        <p>我们仅会在以下情形下，公开披露您的个人信息：</p>
        <p>1. 获得您明确同意后；</p>
        <p>
          2.
          基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，在符合法律法规的前提下，当我们收到上述披露个人信息的请求时，我们会要求接收方必须具有与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的个人信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据；
        </p>
        <p>
          3. 基于我们正常管理与维护“{{
            appName
          }}”平台秩序的需要，对违规账号、欺诈等严重损害超开心公司合法权益的行为进行处罚公告，公告信息主要包括用户账号、用户违规行为。
        </p>
        <p class="bold">（四）转让</p>
        <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>1. 在获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>2. 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
        <p>
          3.
          在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您的个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p class="bold">（五）共享、公开披露个人信息授权同意的例外情形</p>
        <p class="bold">根据法律法规的规定，在下述情况下，共享、转让、公开披露个人信息无需事先征得您的授权同意：</p>
        <p class="bold">1. 与我们履行法律法规规定的义务相关的；</p>
        <p class="bold">2. 与国家安全、国防安全直接相关的；</p>
        <p class="bold">3. 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p class="bold">4. 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
        <p class="bold">5. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的。</p>
        <p class="bold">6. 您自行向社会公众公开的个人信息；</p>
        <p class="bold">7. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p class="bold">
          8.
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
      </section>

      <section>
        <h5>六、我们如何保护您的个人信息</h5>
        <p>我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</p>
        <p>
          1.
          我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
        </p>
        <p>2. 我们已经取得了以下认证：我们的核心业务系统通过了中华人民共和国网络安全等级保护三级认证的备案与评测。</p>
        <p>
          3. 如我们停止运营“{{
            appName
          }}”平台及相关业务，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
        </p>
        <p>
          4.
          我们采取物理、技术和行政管理安全措施来降低个人信息丢失、误用、非授权访问、披露和更改的风险，包括但不限于传输层数据加密、防火墙和加密存储、物理访问控制以及信息访问授权控制。为此我们设置了安全程序保护您的个人信息不会被未经授权的访问所窃取。
        </p>
        <p>5. 我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
        <p>
          6.
          我们已制定相关的信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。
        </p>
        <p>
          7.
          互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以
          “{{
            appName
          }}”平台推送通知、发送邮件/短消息、电话等方式告知您。难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p>
          8.
          互联网并非绝对安全的环境，我们强烈建议您通过安全方式、使用复杂密码，协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄漏，请您立即根据本政策中提供的联系方式联络我们，以便我们采取相应措施。但在我们知悉此种情况和在合理时间内采取行动前，我们对此不负任何责任。
        </p>
      </section>

      <section>
        <h5>七、本政策如何更新</h5>
        <p>1. 我们的个人信息保护政策可能变更。</p>
        <p>
          2. 未经您明确同意，我们不会削减您及您按照本政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        </p>
        <p>
          3.
          对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过手机发送通知，说明个人信息保护政策的具体变更内容）。
          本政策所指的重大变更包括但不限于：
        </p>
        <p>（1） 我们的服务模式发生重大变化。如处理个人信息的目的、 处理的个人信息类型、个人信息的使用方式等；</p>
        <p>（2） 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
        <p>（3） 个人信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>（4） 您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>（5） 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
        <p>（6） 个人信息安全影响评估报告表明存在高风险时。</p>
      </section>

      <section>
        <h5>十、如何联系我们</h5>
        <p>如果您对本政策有任何疑问、意见或建议，可以通过如下任一方式联系我们：</p>
        <p>1. 发送邮件至【kuaiyinlive888@rings.net.cn】与我们个人信息保护负责人联系。</p>
        <p>
          2.
          除此之外，您还可以将相关函件邮寄至下列地址：【中国（四川）自由贸易试验区成都高新区天府三街199号B区16层B3-2、B4、B5、B6单元】
        </p>
        <p>我们将尽快审核所涉问题，并在验证您的身份后，于十五天内进行回复。</p>
      </section>

      <section>
        <h5>十一、争议解决</h5>
        <p>1. 如果您认为我们的个人信息处理行为损害了您的合法权益，您可向有关政府部门反映。</p>
        <p>
          2.
          本政策以及我们处理您的个人信息事宜引起的争议，您还可以通过向成都超开心信息科技有限公司住所地有管辖权的人民法院提起诉讼的方式寻求解决。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  import data from './data/privacy-protocol.json';

  export default {
    name: 'PrivacyProtocol',
    components: {},
    props: {},
    data() {
      return {
        SDKUrl: 'https://landing.chaokaixin.net/sdk-list-less',
        tableData: data.tableData,
        option: data.option,
        content: '',
        appName: '',
        appNameMap: new Map([['less', { appName: 'Less' }]]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      //   const copyBtn = document.querySelector('#copy-btn');
      let app_name = 'less';

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName} App个人信息保护政策`;
      //   copyBtn['data-clipboard-text'] = this.content;
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
